<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else >
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ร้านค้า
        <v-icon>mdi-chevron-right</v-icon>
        <a @click="toBack('manageshop')"> จัดการร้านค้า  </a>
        <v-icon>mdi-chevron-right</v-icon>
        ประวัติการเดินเครดิต
      </v-card-title>
    </v-card>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                ร้านค้า :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-combobox
                  v-model="shop_id_by_name"
                  :items="shop_id_by_name_items"
                  item-key="text"
                  item-value="value"
                  outlined
                  hide-details
                ></v-combobox>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6"/>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                วันที่ :
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                    ref="search_menu_date_from"
                    v-model="search_menu_date_from"
                    :close-on-content-click="false"
                    :return-value.sync="search_date_from"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computed_search_date_from"
                        @change="value => computed_search_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="search_date_from" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date_from)" no-title :allowed-dates="allowedDates" class="my-0">
                    </v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pt-1 pb-2">
                <v-menu
                    ref="search_menu_date_to"
                    v-model="search_menu_date_to"
                    :close-on-content-click="false"
                    :return-value.sync="search_date_to"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          :value="computed_search_date_to"
                          @change="value => computed_search_date_to = value"
                          autocomplete="off"
                          label="ถึง"
                          outlined
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          hide-details
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="search_date_to" @input="search_menu_date_to = false, $refs.search_menu_date_to.save(search_date_to)" no-title :allowed-dates="allowedDates" class="my-0">
                    </v-date-picker>
                  </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn :elevation="1" color="primary" class="pa-2 mt-1" dark @click="search_data()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>
                <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="Export_to_excel()">
                  <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
                  <span class="fn-12"> Export Excel </span>
                </v-btn>
                <v-btn :elevation="1" v-if="this.EditBalanceShop == 1" color="success" class="pa-2 ml-2 mt-1 hidden-xs-only" @click="balance_action(null, 'add', false)">
                  <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                  เพิ่ม
                </v-btn>
                <v-btn :elevation="1" v-if="this.EditBalanceShop == 1" color="success" class="pa-2 ml-2 mt-1 hidden-sm-and-up" @click="balance_action(null, 'add', true)">
                  <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                  เพิ่ม
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-card elevation="0">
      <v-card-text>
        <v-row class="packhai-row v-card-margin-bottom-30">
          <v-col cols="12" class="pb-2">
            <h2 v-if="balance < 0"> ยอดเงินคงเหลือ : <font class="color-red"> {{formatMoney(balance)}}</font></h2>
            <h2 v-else-if="balance > 0"> ยอดเงินคงเหลือ : <font class="color-green"> {{formatMoney(balance)}}</font></h2>
            <h2 v-else> ยอดเงินคงเหลือ : <font class="color-light-blue"> {{formatMoney(balance)}}</font></h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <!-- PC hidden-xs-only -->
            <v-data-table
              :headers="headers"
              :items="topupHistory.table"
              hide-default-footer
              :items-per-page="9999999999"
              class="packhai-border-table hidden-xs-only"
            >
              <template v-slot:item.Description="{ item }">
                <font v-if="item.Expense != null" class="pl-10">{{item.Description}}</font>
                <font v-else>{{item.Description}}</font>
              </template>
              <template v-slot:item.Income="{ item }">
                <font v-if="item.Income != null" class="color-green"><b>{{formatMoney(item.Income)}}</b></font>
              </template>
              <template v-slot:item.Expense="{ item }">
                <font v-if="item.Expense != null" class="color-red"><b>{{formatMoney(item.Expense)}}</b></font>
              </template>
              <template v-slot:item.Balance="{ item }">
                <font><b>{{ formatMoney(item.Balance) }}</b></font>
              </template>
              <template v-slot:item.edit="{ item }">
                <a @click="balance_action(item, 'edit', false)">
                    <v-icon color="orange darken-3" size="18">fa-edit</v-icon>
                </a>
              </template>
              <template v-slot:item.delete="{ item }">
                <a @click="balance_action(item, 'delete')">
                    <v-icon color="red darken-3" size="18">fa-trash-alt</v-icon>
                </a>
              </template>
            </v-data-table>

            <!-- Mobile hidden-sm-and-up -->
            <v-data-iterator
              :items="topupHistory.table"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                  <v-card>
                    <v-row class="packhai-showproduct-3 pa-2 pb-1">
                      <v-col cols="6" class="px-0 pt-1">
                        {{ item.CreatedDateTime }}
                      </v-col>
                      <v-col cols="6" class="text-right px-0 pt-1">
                        <a @click="balance_action(item, 'edit', true)" class="pa-2">
                            <v-icon color="orange darken-3" size="18">fa-edit</v-icon>
                        </a>
                        <a @click="balance_action(item, 'delete')"  class="pa-2">
                            <v-icon color="red darken-3" size="18">fa-trash-alt</v-icon>
                        </a>
                      </v-col>
                    </v-row>
                    <v-row class="packhai-showproduct-3 pa-2">
                      <v-col cols="12" class="px-0">
                        <font v-if="item.Expense != null" class="pl-10">{{item.Description}}</font>
                        <font v-else>{{item.Description}}</font>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row class="packhai-showproduct-3 pa-2">
                      <v-col cols="4" class="text-center">
                        <b>เข้า</b>
                      </v-col>
                      <v-col cols="4" class="text-center">
                        <b>ออก</b>
                      </v-col>
                      <v-col cols="4" class="text-center">
                        <b>คงเหลือ</b>
                      </v-col>
                      <v-col cols="4" class="text-center pa-2">
                        <font v-if="item.Income != null" class="color-green"><b>{{formatMoney(item.Income)}}</b></font>
                      </v-col>
                      <v-col cols="4" class="text-center pa-2">
                        <font v-if="item.Expense != null" class="color-red"><b>{{formatMoney(item.Expense)}}</b></font>
                      </v-col>
                      <v-col cols="4" class="text-center pa-2">
                        <b>{{ formatMoney(item.Balance) }}</b>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>


          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-dialog v-model="balance_dialog" scrollable :max-width="size_percent_for_mobile" persistent >
      <v-card style="background: #f0f0f0" class="my-0">
        <v-card-title >
          <span> {{ title_text }} </span>
          <v-spacer></v-spacer>
          <v-btn icon  @click="balance_dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row  class="form-group">
            <v-col cols="4" md="3" lg="2"><strong> รายละเอียด : </strong></v-col>
            <v-col cols="8" md="4" lg="10" >
              <v-text-field
                :value="dialog_Description"
                @change="value => dialog_Description = value"
                autocomplete="off"
                ref="dialog_Description"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row  class="form-group" v-show="this.action_type == 'add'">
            <v-col cols="4" md="3" lg="2"><strong> เลือก : </strong></v-col>
            <v-col cols="8" md="4" lg="5" >
              <v-radio-group
                v-model="select_action"
                row
              >
                <v-radio
                  label="เข้า"
                  value="0"
                ></v-radio>
                <v-radio
                  label="ออก"
                  value="1"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row  class="form-group" >
            <v-col cols="4" md="3" lg="2"><strong> เข้า : </strong></v-col>
            <v-col cols="8" md="4" lg="5" >
              <v-text-field
                :value="dialog_Income"
                @change="value => dialog_Income = value"
                autocomplete="off"
                ref="dialog_Income"
                outlined
                type="number"
                :disabled="Income_data"
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row  class="form-group">
            <v-col cols="4" md="3" lg="2"><strong> ออก : </strong></v-col>
            <v-col cols="8" md="4" lg="5" >
              <v-text-field
                :value="dialog_Expense"
                @change="value => dialog_Expense = value"
                autocomplete="off"
                ref="dialog_Expense"
                outlined
                :disabled="Expense_data"
                type="number"
                hide-details
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row  class="form-group">
            <v-col cols="4" md="3" lg="2"></v-col>
            <v-col cols="8" md="4" lg="5" >
              <v-btn dark color="primary" @click="dialog_action()" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { financialService, branchOverviewService } from '@/website/global'
  import { AlertWarning } from '@/website/global_alert'
  import { formatMoney, formatDatetime} from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      balance_dialog: false,
      size_percent_for_mobile : '60%',
      title_text: null,
      action_type: null,
      select_action : '0',
      Income_data: false,
      Expense_data: true,


      topupDialog: false,
      navigete: [],
      transferMoney: '',
      transferDate: '',
      transferHour: new Date().getHours(),
      transferMin: new Date().getMinutes(),
      showPicker: false,
      bankAccount: {
        'selected': null,
        'list': []
      },
      search_menu_date_range: false,
      search_date_range: [],

      balance: 0,
      filterSelect: 1,
      filterData: [
        { 'label': 'ทั้งหมด', 'value': 1 },
        { 'label': 'เติมเงิน', 'value': 2 },
        { 'label': 'ใช้ไป', 'value': 3 }
      ],

      dialog_ID: null,
      dialog_Description: null,
      dialog_Income: null,
      dialog_Expense: null,
      dialog_Income_tmp: null,
      dialog_Expense_tmp: null,
      dialog_Balance: null,

      headers1: [
        { text: 'วันที่', align: 'center', value: 'CreatedDateTime', sortable: false, width: '180px' },
        { text: 'รายละเอียด', align: 'left', value: 'Description', sortable: false},
        { text: 'เข้า', align: 'right', value: 'Income', sortable: false, width: '180px' },
        { text: 'ออก', align: 'right', value: 'Expense', sortable: false, width: '180px' },
        { text: 'คงเหลือ', align: 'right', value: 'Balance', sortable: false, width: '180px' }
      ],

      topupHistory: {
        'headersAll': [
          { text: 'วันที่', align: 'center', value: 'CreatedDateTime', sortable: false, width: '180px' },
          { text: 'รายละเอียด', align: 'left', value: 'Description', sortable: false},
          { text: 'เข้า', align: 'right', value: 'Income', sortable: false, width: '180px' },
          { text: 'ออก', align: 'right', value: 'Expense', sortable: false, width: '180px' },
          { text: 'คงเหลือ', align: 'right', value: 'Balance', sortable: false, width: '180px' },
          { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false, width: '80' },
          { text: 'ลบ', align: 'center', value: 'delete', sortable: false, width: '80' }
        ],
        'table': [],
        'keyTable': 0
      },
      to_excel_data: [],
      footer_props: {
         'items-per-page-options': [50],
         'items-per-page-text': null,
         'disable-items-per-page': true
      },

      // search
      shop_id_by_name: null,
      shop_id_by_name_items: [],

      search_menu_date_from: false,
      search_date_from: null,
      search_menu_date_to: false,
      search_date_to: null,
    }),
    computed: {
      headers: function() {
        var data = null
        if(this.EditBalanceShop == 1) {
          return this.topupHistory['headersAll']
        }else {
          return this.headers1
        }
      },
      computed_search_date() {
        return this.formatDate(this.search_date_range)
      },
      computed_search_date_from() {
        return this.formatDates(this.search_date_from)
      },
      computed_search_date_to() {
        return this.formatDates(this.search_date_to)
      },
    },
    watch: {
      filterSelect: async function() {
        await this.getHistoryBalance()
      },

      select_action: async function(val) {
        if(val == '0'){
          this.Income_data = false
          this.Expense_data = true
          this.dialog_Expense = null
          // this.$refs.dialog_Income.focus()
        } else {
          this.Income_data = true
          this.Expense_data = false
          this.dialog_Income = null
          // this.$refs.dialog_Expense.focus()
        }
      },
    },
    async created() {
      document.title = this.$router.history.current.meta.title
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      this.permission = JSON.parse(localStorage.getItem('permission'))
      this.EditBalanceShop = this.permission.EditShopBalance

      this.shop_id = this.$route.query.sid

      if(this.shop_id == null || this.shop_id ==''){
        this.page_loading = false
        this.$router.push("/manageshop")
      }
      await this.getAllShopOfBranch()

      var shop_data = null
      if (this.shop_id_by_name_items.length > 0) {
        for(var i =0; i < this.shop_id_by_name_items.length ; i++){
            if(parseInt(this.shop_id_by_name_items[i]['value']) == parseInt(this.shop_id)){
              shop_data = this.shop_id_by_name_items[i]
            }
        }
      }
      if(shop_data != null){ this.shop_id_by_name = shop_data  }

      this.getBalance()
      this.getHistoryBalance()
      this.page_loading = false
    },
    methods: {
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDates (date) {
        if (date == null){
          return null
        } else {
          const [year, month, day] = date.split('-')
          return `${day}-${month}-${year}`
        }
      },

      formatDate (date) {
        if (date.length == 0) return null
        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      search_data () {
        this.shop_id = this.shop_id_by_name['value']
        if (this.search_date_from != null && this.search_date_to != null) {

          this.search_date_range = [
            this.search_date_from,
            this.search_date_to
          ]
        } else {
          var nowDate1 = new Date()
          this.search_date_to = nowDate1.toISOString().substr(0, 10)
          nowDate1.setDate(nowDate1.getDate()-7)
          this.search_date_from = nowDate1.toISOString().substr(0, 10)
          this.search_date_range = [
            this.search_date_from,
            this.search_date_to
          ]
        }
        this.getBalance()
        this.getHistoryBalance()
      },

      async getAllShopOfBranch() {
        let response = await axios.post(branchOverviewService+'order/get_all_shop_by_branch', {
          'branchID': localStorage.getItem('Branch_BranchID')
        })
        if (response.data.status == 'Success') {
          this.shop_id_by_name_items = response.data.shop_list
          // this.shop_id_by_name_items.splice(0,0,{
          //   "text": "ทั้งหมด",
          //   "value": null
          // })
        }
        this.loading = false
      },

      async getBalance() {
        let response = await axios.post(financialService+'financialShop/get_balance', {
          'shop_id': this.shop_id
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          this.balance = response.data.data
        }
        this.loading = false
      },
      async getHistoryBalance() {
        this.loading = true
        let response = await axios.post(financialService+'financialShop/get_history_balance', {
          'shop_id': this.shop_id,
          'search_date_range': this.search_date_range
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success') {
          this.topupHistory['table'] = response.data.data
          this.to_excel_data = response.data.data
          this.topupHistory['keyTable']++
          if (response.data.resultDate!=null){
            // this.search_date_range = response.data.resultDate

            this.search_date_from = response.data.resultDate[0]
            this.search_date_to = response.data.resultDate[1]
          }
        }
        this.loading = false
      },
      async Export_to_excel () {
        if(this.to_excel_data.length == 0){
          this.AlertWarning("ไม่พบข้อมูล")
        } else {

          this.loading = true
          let response = await axios.post(financialService+'financialShop/export_excel', {
            'data_export': this.to_excel_data
          }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
          if (response.data.status == 'Success') {
              var filename = response.data.this_path
              window.open(financialService+'financialShop/download/'+filename, "_blank");
              this.loading = false
          }
        }
      },

      balance_action (item, action, isFullscreen) {
        this.action_type = action
        if(isFullscreen == true){
          this.size_percent_for_mobile ='90%'
        }else {
          this.size_percent_for_mobile ='45%'
        }


        if(action == 'add'){
          this.balance_dialog = true
          this.title_text = 'เพิ่มประวัติ balance'
          this.select_action = '0'
          this.Income_data = false
          this.Expense_data = true
          this.dialog_Description = null
          this.dialog_Income = null
          this.dialog_Expense = null

        } else if (action == 'edit') {
          this.balance_dialog = true
          this.title_text = 'แก้ไขประวัติ balance'

          this.dialog_ID = item['ID']
          this.dialog_Description = item['Description']
          this.dialog_Income = item['Income']
          this.dialog_Expense = item['Expense']

          this.dialog_Income_tmp = item['Income']
          this.dialog_Expense_tmp = item['Expense']
          this.dialog_Balance = item['Balance']



          if(item['Income'] != null){
            this.select_action = '0'
            this.Income_data = false
            this.Expense_data = true
          }else {
            this.select_action = '1'
            this.Income_data = true
            this.Expense_data = false
          }

        }else if (action == 'delete') {
          // this.balance_dialog = true
          this.dialog_ID = item['ID']
          this.$swal({
            position: "top",
            type: 'warning',
            title: 'ลบ ใช่หรือไม่ !!',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            allowOutsideClick: false
          }).then(async(result) => {
            if (result.value) {
              this.dialog_action()
            }
          })
        }
      },

      async dialog_action () {
        if (this.action_type == 'add'){
            if(this.dialog_Description == null || this.dialog_Description == '' ){
              this.$refs.dialog_Description.focus()
              return
            }
            if (this.select_action == '0') {
              if(this.dialog_Income == null || this.dialog_Income == '' ){
                this.$refs.dialog_Income.focus()
                return
              }
            }
            if (this.select_action == '1') {
              if(this.dialog_Expense == null || this.dialog_Expense == '' ){
                this.$refs.dialog_Expense.focus()
                return
              }
            }

            var data_balance1 = {
              "ShopID": this.shop_id,
              "Income": this.dialog_Income,
              "Expense": this.dialog_Expense,
              "Description": this.dialog_Description,
              "ID": null,
            }
            this.loading = true
            let response = await axios.post(financialService+'financialShop/add_balance_history', {
              'data_balance': data_balance1
            })
            if (response.data.status == 'Success') {
              this.loading = false
              this.balance_dialog = false
              this.search_date_range = []
              this.getBalance()
              this.getHistoryBalance()
            }

        } else if (this.action_type == 'edit') {

            if(this.dialog_Description == null || this.dialog_Description == '' ){
              this.$refs.dialog_Description.focus()
              return
            }

            if (this.select_action == '0') {
              if(this.dialog_Income == null || this.dialog_Income == '' ){
                this.$refs.dialog_Income.focus()
                return
              }
            }
            if (this.select_action == '1') {
              if(this.dialog_Expense == null || this.dialog_Expense == '' ){
                this.$refs.dialog_Expense.focus()
                return
              }
            }

            var data_balance2 = {
              "ShopID": this.shop_id,
              "Income": this.dialog_Income,
              "Expense": this.dialog_Expense,
              "Income_tmp": this.dialog_Income_tmp,
              "Expense_tmp": this.dialog_Expense_tmp,
              "Description": this.dialog_Description,
              "Balance_old": this.dialog_Balance,
              "ID": this.dialog_ID
            }

            this.loading = true
            let response = await axios.post(financialService+'financialShop/edit_balance_history', {
              'data_balance': data_balance2
            })
            if (response.data.status == 'Success') {
              this.loading = false
              this.balance_dialog = false
              this.search_date_range = [this.search_date_from, this.search_date_to]
              this.getBalance()
              this.getHistoryBalance()
            }

        } else if (this.action_type == 'delete') {
            let response = await axios.post(financialService+'financialShop/delete_balance_history', {
              'history_id': this.dialog_ID,
              'ShopID': this.shop_id
            })
            if (response.data.status == 'Success') {
              this.loading = false
              this.balance_dialog = false
              this.search_date_range = [this.search_date_from, this.search_date_to]
              this.getBalance()
              this.getHistoryBalance()
            }
        }
      },
      toBack(path){
        this.$router.push('/'+path)
      },
      formatMoney,
      formatDatetime,
      AlertWarning,
    }
  }
</script>

<style scoped>
  >>>.v-select__slot{
    height: 40px;
  }
  >>>.v-input__prepend-inner{
    margin-top: 10px;
  }
</style>
